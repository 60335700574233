export enum Spec {
  Autocomplete = 'specs.siteSearch.Autocomplete',
  DefaultImageResizeModeFit = 'specs.siteSearch.DefaultImageResizeModeFit',
  ImageResizeOptions = 'specs.siteSearch.ImageResizeOptions',
  NewStoresAppDefId = 'com.wixpress.search.commons.specs.NewStoresAppDefIdSpec',
  RemoveLabelOverrideToggle = 'specs.siteSearch.RemoveLabelOverrideToggle',
  SeoHiddenPages = 'specs.siteSearch.SeoHiddenPages',
  ScrollToWidget = 'specs.siteSearch.ScrollToWidget',
  HideEmptyTabs = 'specs.siteSearch.HideEmptyTabs',
  ProGallery = 'specs.siteSearch.ProGallery',
  ForumFacets = 'specs.siteSearch.ForumFacets',
  ForumTabUpdate = 'specs.siteSearch.ForumTabUpdate',
  AddToCartQuickView = 'specs.siteSearch.AddToCartQuickView',
}
